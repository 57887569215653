<template>
  <b-overlay :show="is_busy" rounded="sm">
    <div id="printMe" class="">
        <div class="container-fluid" v-for="stt in students" :key="stt.id"> 
            <section class="card" style="margin-bottom:400px" v-if="stt.student">    
                <div class="card-body border">
                    <!-- <div class="row text-center mb-1">
                        <div class="col-lg-2 col-md-2 col-sm-2">
                            <img :src="school.logo" class="img-fluid" style="height:70px" v-if="school.logo">
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9">
                            <span style="font-size: 20px; font-weight: bold">{{ school.school_name }}</span>
                            <br> {{ school.slogan }}<br>
                            {{ school.address }}
                        </div>
                    </div> -->
                    <img :src="school.logo" class="img-fluid" style="display: block; margin-left: auto; margin-right: auto; width: 120px;" v-if="school.logo">
    

                    <div class="table-responsive table-sm">
                        <p style="text-align: center; color:#63a4cc; font-weight: bold;">SUMMARY RECORD:</p>
                        <table class="table table-bordered">
                        <thead style="font-weight: bold; color: #000; text-transform: uppercase;">
                            <tr class="text-center">
                            <th>Session</th>
                            <th>Term</th>
                            <th>Class</th>
                            <th>Admission Number</th>
                            <th>Sex:</th>
                            <th>Fullname</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center">
                            <td>{{ session.name }}</td>
                            <td>{{ term }}</td>
                            <td>{{ room.title }}</td>
                            <td>{{ stt.student.roll }}</td>
                            <td>{{ stt.student.gender | capitalize }}</td>
                            <td>{{ stt.student.lastname }} {{ stt.student.firstname }} {{ stt.student.middlename }}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                
                    <div class="row">
                        <div class="col-4 col-md-4">
                        <p style="text-align: center; color:#63a4cc; font-weight: bold;">Attendance Record</p>
                        <div class="table-responsive table-sm">
                            <table class="table table-bordered">
                            <thead style="font-weight: bold; color: #000; text-transform: uppercase;">
                                <tr class="text-center">
                                <th>All:</th>
                                <th>Present:</th>
                                <th>Absent:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-center">
                                <td> 
                                    -
                                </td>
                                <td>
                                    -
                                </td>
                                <td>
                                    -
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>

                        <div class="col-8 col-md-8">
                        <p style="text-align: center; color:#63a4cc; font-weight: bold;">CA CONVERSIONS: CA = {{ 100  - standard.exam }}, EXAM = {{ standard.exam }}, TOTAL = 100</p>
                        <div class="table-responsive table-sm">
                            <table class="table table-bordered text-center">
                            <thead style="font-weight: bold; color: #000; text-transform: uppercase; text-align:center"> 
                                <tr>
                                <th>Total Score:</th>
                                <th>Total Subject:</th>
                                <th>Average Score:</th>
                                <th>Class Average:</th>
                                <!--<th>Position</th>-->
                                <th>Total Students:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>{{ stt.total_score }}</td>
                                <td>{{ stt.result.length }}</td>
                                <td>{{ stt.avg_score }}</td>
                                <td>{{ class_avg }}</td>
                                <!--<td>{{ position }}</td>-->
                                <td>{{ total_student }}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>   
                    </div>

                    <div class="row ">
                        <div class="col-9 col-md-9">
                            <p style="text-align: center; color:#63a4cc; font-weight: bold;">ACADEMIC RECORD:</p>
                            <div class="table-responsive table-sm">
                                <table class="table table-bordered text-center" v-if="term!='Annual'">
                                <thead style="color:blue; text-transform: uppercase; font-size: 13px; font-weight:bolder">
                                    <tr>
                                    <th>Subject</th>
                                    <th>1st CA ({{ standard.test1 }}%)</th> 
                                    <th v-if="standard.assessment>1">2nd CA ({{ standard.test2 }}%)</th> 
                                    <th v-if="standard.assessment>2">3rd CA ({{ standard.test3 }}%)</th> 
                                    <th v-if="standard.assessment>3">4th CA ({{ standard.test4 }}%)</th>
                                    
                                    <th>Exams ({{ standard.exam }}%)</th>
                                    <th>Total (100%)</th>
                                    <th>Grade Point</th>
                                    <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="result in stt.result" :key="result.id">
                                    <td>{{ result.get_subject }}</td>
                                    <td>{{ formatNumber(result.first_test) }}</td>
                                    <td v-if="standard.assessment>1">{{ formatNumber(result.second_test) }}</td>
                                    <td v-if="standard.assessment>2">{{ formatNumber(result.third_test) }}</td>
                                    <td v-if="standard.assessment>3">{{ formatNumber(result.forth_test) }}</td>
                                    <td>{{ formatNumber(result.exam) }}</td>
                                    <td>{{ formatNumber(result.total) }}</td>
                                    <td>{{ result.grade }}</td>
                                    <td>{{ result.comment }}</td>
                                    </tr>
                                </tbody>                                   
                                </table>

                                <table class="table table-bordered text-center" v-else>
                                <thead style="color:blue; text-transform: uppercase; font-size: 13px; font-weight:bolder">
                                    <tr>
                                    <th>Subject</th>
                                    <th>Total CA ({{ Number(standard.test1) + Number(standard.test2) + Number(standard.test3) + Number(standard.test4) }}%)</th> 
                                    <th>Exams ({{ standard.exam }}%)</th>
                                    <th>Total (100%)</th>
                                    <th>Grade Point</th>
                                    <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="result in stt.result" :key="result.id">
                                    <td>{{ result.get_subject }}</td>
                                    <td>{{ formatNumber(Number(result.first_test) + Number(result.second_test) + Number(result.third_test) + Number(result.forth_test)) }}</td>
                                    <td>{{ formatNumber(result.exam) }}</td>
                                    <td>{{ formatNumber(result.total) }}</td>
                                    <td>{{ result.grade }}</td>
                                    <td>{{ result.comment }}</td>
                                    </tr>
                                </tbody>                                   
                                </table>
                            </div>
                        </div>

                        <div class="col-3 col-md-3">
                            <p style="text-align: center; color:#63a4cc; font-weight: bold;">PERSONAL ANALYSIS:</p>
                            <div class="table-responsive table-sm">
                                <table class="table table-bordered p-1">
                                <tr>
                                    <th style="color:blue; font-size:17px; font-weight:bolder">PSYCHOMOTOR:</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>HANDWRITING: </th>
                                    <td style="width:50px">
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.handwriting }}</span> 
                                    </td>
                                </tr>
                                <tr>
                                    <th>VERBAL FLUENCY:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.verbal_fluency }}</span> 
                                    </td>
                                </tr>
                                <tr>
                                    <th>GAMES, SPORTS:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.games }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>CONSTRUCTION:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.construction }}</span></td>
                                </tr>
                                <tr>
                                    <th>DRAWING, PAINTING & CRAFT:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.drawing }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>MUSICAL SKILLS:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.musical }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th style="color:blue; font-size:16px; font-weight:bolder">EFFECTIVE AREAS:</th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>PUNCTUALITY:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.punctiality }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>ALERTNESS:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.alertness }}</span></td>
                                </tr>
                                
                                <tr>
                                    <th>NEATNESS:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.neatness }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>POLITENESS:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.politeness }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>HONESTY:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.honesty }}</span>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <th>FRIENDSHIP:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.friendship }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>SELF CONTROL:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.self_control }}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <th>INDUSTRIOUS:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.industrious }}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <th>GENEROUSITY:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.generousity }}</span>
                                    </td>
                                </tr>

                                    <tr>
                                    <th>ADJUSTMENT:</th>
                                    <td>
                                    <span class="p-1" v-if="stt.assessgrade">{{ stt.assessgrade.adjustment }}</span>
                                    </td>
                                </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row ">
                        <div class="col-3 col-md-3">
                        <h4 class="text-center">KEYS</h4>
                        <div class="table-responsive table-sm">
                            <table class="table table-bordered">
                            <tr>
                                <th>{{ standard.a_min}} - 100</th>
                                <td>Excellent</td>
                                <td class="p-1">A</td>
                            </tr>
                            <tr>
                                <th>{{ standard.b_min}} - {{ standard.b_max}}</th>
                                <td>Very Good</td>
                                <td class="p-1">B</td>
                            </tr>
                            <tr>
                                <th>{{ standard.c_min}} - {{ standard.c_max}}</th>
                                <td>Good</td>
                                <td class="p-1">C</td>
                            </tr>
                            <tr>
                                <th>{{ standard.d_min}} - {{ standard.d_max}}</th>
                                <td>Pass</td>
                                <td class="p-1">D</td>
                            </tr>
                            <tr>
                                <th>{{ standard.e_min}} - {{ standard.e_max}}</th>
                                <td>Weak Pass</td>
                                <td class="p-1">E</td>
                            </tr>
                            <tr>
                                <th>{{ standard.f_min}} - {{ standard.f_max}}</th>
                                <td>Fail</td>
                                <td class="p-1">F</td>
                            </tr>
                            </table>
                        </div>
                        </div>

                        <div class="col-6 col-md-6">
                        <div class="border p-1">
                            <p class="text-center">
                            <b>Class Teacher's Comment</b><br> 
                            <span v-if="stt.assessgrade">
                                {{ stt.assessgrade.teacher_comment }}
                            </span><br>
                            <i>{{ room.form_teacher }}</i>
                            </p>
                            
                            <p class="text-center">
                            <b><span v-if="school.category==0">Head Teacher's </span><span v-else>Principal's </span>Comment</b><br> 
                            <span v-if="stt.assessgrade">
                                {{ stt.assessgrade.principal_comment }}
                            </span><br>
                            <i>{{ head }}</i>
                            </p>
                        </div> 
                        </div>

                        <div class="col-3 col-md-3">
                        <div class="text-center p-1">
                            <p style="font-size: 16px; text-align: center">Computer Overall Remark</p>
                            <h3 style="font-weight: bolder; color:red" v-if="stt.avg_score <=  standard.f_max">FAIL</h3>

                            <h3 style="font-weight: bolder; color:red" v-else-if="stt.avg_score <=  standard.e_max">WEAK PASS</h3>

                            <h3 style="font-weight: bolder; color:pink" v-else-if="stt.avg_score <=  standard.d_max">PASS</h3>

                            <h3 style="font-weight: bolder; color:green" v-else-if="stt.avg_score <=  standard.c_max">Good</h3>

                            <h3 style="font-weight: bolder; color:green" v-else-if="stt.avg_score <=  standard.b_max">VERY GOOD</h3>

                            <h3 style="font-weight: bolder; color:green" v-else>EXCELLENT</h3>
                        </div>
                        </div> 
                    </div> 
                </div> 
            </section>
        </div>
    </div>
    <div class="my-2">
        <button v-print="printObj" class="btn btn-info btn-sm float-right">Print Result</button>
        <div id="loading" v-show="printLoading"></div>
    </div>
  </b-overlay>
</template>

<script>
  import axios from 'axios';

  export default {
    created(){
      this.loadData();
    },
    
    data(){
      return{
        condiParam: {
          class_id: '',
          session_id: '',
          term_id: '',
        },
        total_score: '',
        total_student: '',
        class_avg: '',
        position: '',
        results: {},
        standard: '',
        term: '',
        session: '',
        subject: '',
        school:'',
        students: '',
        stt : {
            student: '',
        },
        is_busy: false,
        room: '',
        assessgrade: '',
        head: '',
        printLoading: true,
        printObj: {
            id: "printMe",
            extraCss: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css",
            extraJs: "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js",
            extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
            beforeOpenCallback (vue) {
                vue.printLoading = true
            },
            openCallback (vue) {
                vue.printLoading = false
            },
            closeCallback (vue) {
            }
        }
      }
    },

    methods: {
      loadData(){
        this.condiParam.session_id = this.$route.params.session_id;
        this.condiParam.term_id = this.$route.params.term_id;
        this.condiParam.class_id = this.$route.params.class_id;

        if(!this.condiParam.class_id  || !this.condiParam.session_id  || !this.condiParam.term_id)
        {
          Swal.fire(
            "Failed!",
            "Ops, One of the needed parameters are not set, try again.",
            "warning"
          );
          this.$router.push({path: '/result/add'});
        }
      
        if (this.is_busy) return;
        this.is_busy = true;
        axios.get("/result/view/all", { params: this.condiParam }) 
        .then((response)=>{ 
            this.standard = response.data.data.standard;
            this.session = response.data.data.session;
            this.term = response.data.data.term;
            this.school = response.data.data.school;
            this.class_avg = response.data.data.class_avg;
            this.students = response.data.data.students;
            this.total_student = response.data.data.total_student;
            this.room = response.data.data.room;
            this.head = response.data.data.head;
        })
        .catch((err)=>{
          Swal.fire(
              "Error!",
              err.response.data.data.error,
              "error"
          );
          this.$router.back()
        })
        .finally(() => {
            this.is_busy = false;
        });
      },

      formatNumber(value) {
        if (Number.isInteger(value)) {
           return Number(value)
        }
        else {
          return Number(value).toFixed(1)
        }
      },
    },
  }
</script>

<style>
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: white;
    background-color: red;
    border-color: #dee2e6 #dee2e6 #f8fafc;
  }

  .invoice {
    padding: 1px;
  }
    .firstdiv{
      font-size: 13px; 
      font-weight: bold; 
    }

    .table th, .table td {
      padding: 0.25rem !important;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
      font-size: 12px;
  }
  </style>